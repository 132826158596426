import ctaBGImage from "assets/images/ctaBG.png";
import cta1Image from "assets/images/cta1.png";
import cta2Image from "assets/images/cta2.png";
import cta3Image from "assets/images/cta3.png";

export default function Feature() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8 lg:pr-0">
        <div
          className="relative isolate overflow-hidden bg-landingCtaBg px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0 lg:pr-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Get credit analysis done in super-smart way.
            </h2>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <div className="text-sm font-semibold leading-6 text-white">
                <span aria-hidden="true">✔</span> No Hidden Fees
              </div>
              <div className="text-sm font-semibold leading-6 text-white">
                <span aria-hidden="true">✔</span> Secure Transactions
              </div>
            </div>
          </div>
          <div className="relative flex-1 bg-cover bg-center"
               style={{
                 backgroundImage: `url(${ctaBGImage})`,
                 backgroundPosition: 'left',
               }}
          >
            <div className="lg:grid lg:grid-cols-2 lg:gap-4 p-4 pt-12">
              <div className="flex items-center justify-center mb-4 lg:mb-0">
                <img
                  src={cta1Image}
                  alt="Description"
                  className="object-contain max-w-xs h-auto"
                />
              </div>
              <div className="flex items-center justify-center mb-4 lg:mb-0">
                <img
                  src={cta2Image}
                  alt="Description"
                  className="object-contain max-w-xs h-auto"
                />
              </div>
            </div>

            <div className="flex items-center justify-center p-4">
              <img
                src={cta3Image}
                alt="Description"
                className="object-contain max-w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
