import { EnvelopeOpenIcon, ArrowUpOnSquareStackIcon, ComputerDesktopIcon } from '@heroicons/react/20/solid'
import brainImage from "assets/images/brain.svg"
import processBGImage from "assets/images/processBG.png";

const features = [
  {
    name: 'Fill Basic Details',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: EnvelopeOpenIcon,
  },
  {
    name: 'Fill Employment Details',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: ArrowUpOnSquareStackIcon,
  },
  {
    name: 'Get Credit Analysis Report',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
    icon: ComputerDesktopIcon,
  },
]

function Card({ number, title, description }) {
  return (
    <div className="bg-landingCtaBg max-w-md mx-auto rounded-lg overflow-hidden shadow-lg p-4">
      <div className="flex h-full">
        {/* First Column */}
        <div className="flex items-center justify-center text-white text-8xl font-bold p-4">
          {number}
        </div>

        {/* Second Column */}
        <div className="flex flex-col justify-between ml-4">
          {/* Title Row */}
          <div className="text-xl font-semibold text-white">
            {title}
          </div>

          {/* Description Row */}
          <div className="text-white">
            {description}
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Process() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32"
         style={{
           backgroundImage: `url(${processBGImage})`,
           backgroundRepeat: 'no-repeat',
           backgroundSize: 'cover',
           backgroundPosition: 'center',
         }}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            How it works?
          </h1>
          <p className="text-lg leading-8 text-gray-600 mb-8">
            Steps to get credit analysis done in super-smart way.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
          <div className="flex justify-center sm:px-6 lg:px-0">
            <div className="relative overflow-hidden mx-auto w-full lg:max-w-none">
              <img
                src={brainImage}
                alt="Product screenshot"
                className="w-full h-auto lg:w-auto lg:max-w-none"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center h-full px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature, index) => (
                  <Card number={index + 1} title={feature.name} description={feature.description} />
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
