const stats = [
  { id: 1, name: 'App Downloads', value: '5k+' },
  { id: 2, name: 'Credit Report Analysed', value: '1k+' },
  { id: 3, name: 'Bills Paid Successfully', value: '500+' },
  { id: 4, name: 'Investment Advises Provided', value: '1k+' },
]

export default function Stats() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Get to know about us
            </h1>
          </div>
          <dl
            className="mt-16 grid grid-cols-1 gap-x-5 gap-y-5 overflow-hidden text-center sm:grid-cols-2 lg:grid-cols-4 mx-auto max-w-7xl">
            {stats.map((stat) => (
              <div key={stat.id}
                   className="flex flex-col items-center justify-center bg-white rounded-2xl border-40 border-blue-50 shadow-lg p-6 space-y-4">
                <dd className="text-3xl font-extrabold text-gray-900 rounded-t-2xl">{stat.value}</dd>
                <dt className="text-sm font-semibold text-gray-600 rounded-b-2xl">{stat.name}</dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
