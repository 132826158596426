import heroImage from "assets/images/hero@3x.png";
import {Link} from "react-router-dom";

export default function Hero() {
  return (
    <div className="relative isolate overflow-hidden bg-white">
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" strokeWidth={0} fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)" />
      </svg>
      <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40 lg:space-x-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-1 lg:pt-8">
          <div className="mt-24 sm:mt-32 lg:mt-16">
            <Link to="/" className="inline-flex space-x-6">
              <span className="rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
                Coming Soon
              </span>
            </Link>
          </div>
          <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            <span className="text-customPrimary">
              Let's Borrow Smartly with
            </span>
            {" "}LoanStory
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Unlock your financial potential with a instant credit score and comprehensive report.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <button
              type="button"
              className="rounded-md bg-primaryButtonBg px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-customPrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Check Your Credit Analysis
            </button>
          </div>
        </div>
        <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-1 lg:max-h-full drop-shadow-xl">
          <div className="mx-auto">
            <img src={heroImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
