import Hero from "components/landing/Hero";
import FAQ from "components/landing/FAQ";
import Clients from "components/landing/Clients";
import Blogs from "components/landing/Blogs";
import Feature from "components/landing/Feature";
import Stats from "components/landing/Stats";
import Process from "components/landing/Process";
import Services from "components/landing/Services";

export default function Home() {
  return (
    <>
      <Hero />
      <Stats />
      <Process />
      <Services />
      <Feature />
      <Blogs />
      <Clients />
      <FAQ />
    </>
  );
}