import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "components/common/Navbar"
import Footer from "components/common/Footer"

const MainLayout = () => {
  return (
    <>
      <div className="bg-white">
        <Navbar />
        <main>
          <Outlet /> {/* This is where your page components will be rendered */}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MainLayout;
