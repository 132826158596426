import service1Image from "assets/images/service1.png"
import service2Image from "assets/images/service2.png"
import service3Image from "assets/images/service3.png"

export default function Services() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mb-10">
            Our <span className="text-secondaryColor">Services</span>
          </h1>
        </div>

        {/* First Row */}
        <div className="grid grid-cols-1 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-stretch">
          <div className="flex justify-center sm:px-6 lg:px-0">
            <div className="relative overflow-hidden sm:mx-auto w-full lg:max-w-none">
              <img
                src={service1Image}
                alt="Product screenshot"
                className="w-full h-auto lg:self-stretch lg:max-w-none"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center h-full px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h1 className="text-4xl font-normal tracking-tight text-gray-900 sm:text-4xl mb-10">
                <span className="font-bold">Credit Report </span>Analysis
              </h1>
              <p className="text-lg leading-8 text-gray-600 mb-8">
                Lorem ipsum dolor sit amet consectetur. Maecenas non nulla sed consectetur sagittis. Interdum at aliquet suspendisse
              </p>
            </div>
          </div>
        </div>

        {/* Second Row */}
        <div className="grid grid-cols-1 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-stretch">
          <div className="flex flex-col justify-center h-full px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h1 className="text-4xl font-normal tracking-tight text-gray-900 sm:text-4xl mb-10">
                LoanStory <span className="font-bold">Analysis Report</span>
              </h1>
              <p className="text-lg leading-8 text-gray-600 mb-8">
                Lorem ipsum dolor sit amet consectetur. Maecenas non nulla sed consectetur sagittis. Interdum at aliquet suspendisse
              </p>
            </div>
          </div>
          <div className="flex justify-center sm:px-6 lg:px-0">
            <div className="relative overflow-hidden sm:mx-auto w-full lg:max-w-none">
              <img
                src={service2Image}
                alt="Product screenshot"
                className="w-full h-auto lg:self-stretch lg:max-w-none"
              />
            </div>
          </div>
        </div>

        {/* Third Row */}
        <div className="grid grid-cols-1 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-stretch">
          <div className="flex justify-center sm:px-6 lg:px-0">
            <div className="relative overflow-hidden sm:mx-auto w-full lg:max-w-none">
              <img
                src={service3Image}
                alt="Product screenshot"
                className="w-full h-auto lg:self-stretch lg:max-w-none"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center h-full px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h1 className="text-4xl font-normal tracking-tight text-gray-900 sm:text-4xl mb-10">
                <span className="font-bold">Loan Enquiry </span>Service
              </h1>
              <p className="text-lg leading-8 text-gray-600 mb-8">
                Lorem ipsum dolor sit amet consectetur. Maecenas non nulla sed consectetur sagittis. Interdum at aliquet suspendisse
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
